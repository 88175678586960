// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

// Firebase imports break the Gatsby build because of use of window.
// The following is a workaround
let firebaseInstance;
export const getFirebase = (firebase) => {
  if (firebaseInstance) {
    return firebaseInstance;
  }

  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebaseInstance = firebase;

  return firebase;
};
