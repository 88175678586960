import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import theme from '../../src/theme';
import useFirebase from '../../src/utils/constants/firebase/useFirebase';
import './TopLayout.scss';

export const TopLayout = ({ children }) => {
  useFirebase();

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="preconnect stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap"
        />
        <meta property="og:image" content="/social-img.jpeg" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hummingbird.fit/" />
        <meta
          property="og:title"
          content="Hummingbird - Real-time Trainee Metrics"
        />
        <meta
          property="og:description"
          content="Hummingbird helps tailor your workout seession with real-time trainee metrics."
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <div id="outer-container" className="hbm--app">
          {children}
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};
