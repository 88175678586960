import { useEffect, useState } from 'react';
import { getFirebase } from './firebase';

export default function useFirebase() {
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    // Avoid importing until mounted to avoid breaking the SSR build
    const lazyApp = import('firebase/app');
    const lazyAnalytics = import('firebase/analytics');

    Promise.all([lazyApp, lazyAnalytics]).then(([firebase]) => {
      setInstance(getFirebase(firebase.default));
    });
  }, []);

  return instance;
}
